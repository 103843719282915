@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import "./global.scss";




.primary-color, .text-primary {
  color: var(--primClr) !important;
}

.primary-bgcolor, .bg-primary {
  background-color: var(--color1) !important;
}
.bg-primary-lgth{
  background-color: var(--primClr) !important;
}
.btn-primary {
  background-color: var(--color1) !important;
  border-color: var(--color2);

  &:hover {
    background-color: var(--color3) !important;
  }
}

.btn-outline-primary {
  border-color: var(--color2);
  color: var(--color1);

  &:hover {
    background-color: var(--color3) !important;
  }
}

.list-group li, {
  color: var(--inverse-b-w);
  background-color: var(--color2) !important;
}

.card, .card-body {
  color: var(--inverse-b-w);
  background-color: var(--color2);
  border-color: var(--card-border);
}

.form-control, .form-select {
  background-color: var(--inputcolor);
  border: 1px solid var(--inputcolorborder);
  outline: none !important;
  color: var(--inverse-w-b) !important;

  &:focus {
    background-color: var(--color2) !important;
    box-shadow: none;
  }
}

.dynmatxt {
  color: var(--inverse-b-w) !important;
}

body {
  background-color: var(--body-color);
  padding: 10px;
  transition: background-color 1s;
  height: 100vh;
  overflow: auto;
}


.content {
  padding: 15px;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: auto;

  .topbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
.nav-tabs .nav-link{
color: var(--inverse-b-w);
}
.menutoggler {
  display: none;
  color: var(--inverse-b-w);
  margin-right: 30px;
  cursor: pointer;
}

.switchTheme {
  color: var(--inverse-b-w);

  i {
    cursor: pointer;
  }
}

.cursor {
  cursor: pointer !important;
}

.closemenu {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.leftside {
  background-color: var(--primClr);
  width: 305px;
  height: 100%;
  border-radius: 10px;
  position: relative;
  z-index: 3;


  .profile-section {
    height: 245px;
    text-align: center;
    padding-top: 30px;
    position: relative;
    z-index: 10;

    .profileName {
      font-size: 1rem;
    }

    img {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      display: block;
      margin: 0 auto;
    }

    .icon-frame {
      border-radius: 50%;
      padding: 10px;
      border: 4px solid #ffffff;
      margin: 0 auto;
      width: 128px;
      height: 128px;

      i {
        font-size: 5.8rem;
        color: white;
      }
    }


    h4 {
      color: white;
      margin-top: 10px;
    }

    .badge {
      background-color: var(--color1);
      color: white;
      margin-bottom: 30px;
    }
  }

  .menu-section {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--color3);
    padding: 20px;
    height: 70vh;
    position: relative;
    top: 25px;


    ul {
      list-style: none;
      margin: 45% 0 0 0;
      padding: 0;
      //overflow: auto;

    }

    li {
      margin: 25px 10px;
      position: relative;

      a {
        color: white;
        text-decoration: none;
        font-size: 1.2rem;

        span {
          font-weight: 400;
        }

        i {
          margin-right: 20px;
        }
      }

      .submenu {
        display: none;
        transition: display 1s;
        position: absolute;
        //height: 100px;
        width: 200px;
        z-index: 9999999;
        left: 43%;
        top: 20%;
        background-color: var(--primClr);

        .menu-title {
          padding: 7px;
          margin-bottom: 0;
          color: white;
          text-transform: uppercase;
          font-size: 17px;
          font-weight: 600;
          border-bottom: 1px solid #07184d;
        }

        ul {
          margin-top: 0;
          padding: 5px 0 0 0;

          li {
            margin: 4px 0 0 0;
            padding: 10px 8px;
            font-size: 13px;
            border-bottom: 1px solid #07184d;

            a {
              background-color: unset !important;
              padding: 0 !important;
              border-radius: unset !important;
              font-size: 15px;
              display: block;
            }

            &:hover {
              background-color: var(--color3);
            }
          }
        }
      }

      .active-menu::after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 5px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #69b6d5;

      }

      &:hover {
        .submenu {
          display: block;
        }

      }
    }

    .support-btn {
      width: 85%;
      background-color: var(--primClr);
      border-radius: 10px;
      position: absolute;
      bottom: 5%;

      a {
        color: rgb(183, 180, 180);
        font-weight: 400;
      }
    }
  }

  .show-menu {
    display: block;
    position: absolute;
  }
}

.notification-item {
  background-color: var(--primClrlight);

  &.active {
    background-color: var(--primClr) !important;
    color: #000 !important;
  }


  &.unread {
    background-color: var(--primClr) !important;

    h6 {
      font-size: 18px;
      color: #fff !important;;
    }

    small, p {
      font-weight: 500;
      color: #fff !important;
      font-size: 13px;
    }
  }

  h6 {
    font-size: 18px;
    color: var(--inverse-b-w);
  }

  small, p {
    font-weight: 500;
    color: var(--inverse-b-w);
    font-size: 13px;
  }

  .notification-icon {
    border-radius: 50%;
    background-color: var(--backgrdcolor3);
    padding: 10px 15px;
    margin: 0 5px;
    width: 45px;
    height: 45px;

    i {
      color: #fff !important;
    }
  }

  &:hover {
    background-color: var(--notificationHover);
  }
}

.purple-gradient-text {
  background: -webkit-linear-gradient(left, #a445b2, #fa4299);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.projectswicther {
  background-color: var(--projeswth-color);
  border-radius: 50px;
  padding: 7px 15px;
  margin-top: 5px;

  .nav-item {
    .active {
      border-radius: 50px;
      background-color: var(--color3);

      span {
        color: #fff !important;
      }
    }

    .nav-link {
      img {
        height: 30px;
      }

      span {
        color: var(--inverse-b-w);
      }
    }
  }
}

.feedbox {
  height: 400px;
  overflow-y: auto;
  background-color: var(--backgrdcolor3) !important;

  .card-body {
    background-color: var(--backgrdcolor3) !important;

    .list-group-item {
      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }
    }
  }

}

.css-jz6rhf {

}

.e-library {
  .filter {
    height: 70vh;

  }

  .categories {
    height: 15%;
    overflow: auto;

    ul {
      padding-left: 1rem;
    }

    li {
      cursor: pointer;

      &:hover {
        color: #cbcbcb;
      }
    }

    h4 {
      text-transform: uppercase;
      @include md {
        font-size: 18px;
      }
      @include lg {
        font-size: 20px;
      }
    }
  }

  .library-assets {
    min-height: 179px !important;

    .assettype {
      border: 1px solid dodgerblue;
      border-radius: 5px;
      color: dodgerblue;
      font-size: 10px;
      padding: 7px 2px
    }
  }
}

.list-style-no {
  list-style: none;
}

textarea {
  height: 90px !important;
}

.modal-content {
  background-color: var(--color2);
}

.modal-title {
  color: var(--inverse-b-w);
}

.table > :not(caption) > * > * {
  background-color: var(--inputcolor);
  color: var(--inverse-b-w);
}

.tranDetailsidenav {
  height: 100%;
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  overflow: auto;
  background-color: var(--backgrd);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  .xmalltxt {
    font-size: 13px;
  }
}

.tranDetailsidenav .transdetailclosebtn i {
  font-size: 20px;
  color: firebrick;
  //position: absolute;
  //top: 0;
  //right: 25px;
  //margin-left: 50px;
}

.viewTrans {
  background-color: var(--color2);
  display: block;
  width: 450px;

}

@media screen and (max-height: 450px) {
  .tranDetailsidenav {
    padding-top: 15px;
  }
}

.scriptViewZone {
  height: 350px;
  overflow-y: auto;
  border: #a7acb1 1px solid;
  border-radius: 8px;
  margin-top: 25px;
}
.scriptViewZoneLect {
  height: 70vh;
  overflow-y: auto;
  border: #a7acb1 1px solid;
  border-radius: 8px;
  margin-top: 25px;
}

.w-15{
  width: 17% !important;
}
.notification-bellcounter{
  font-size: 0.8rem;
}


.notificationboard-viewmore{
  text-decoration: none;
  span,i{
    font-size: 14px;

    color: white;
  }
  span{
    @include sm{
      display: none;
    }
  }

}
