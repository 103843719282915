/* // `sm` applies to x-small devices (portrait phones, less than 576px)  MEDIA QUERY FOR ALL MOBILE PHONES*/
@media (max-width: 575.98px) {
  .viewTrans {
    width: 300px !important;
  }
  .projectswicther-top, .content .topbar {
    display: block;
  }
  .projectswicther {
    display: flex;
    justify-content: space-between;

    .nav-link {
      padding: 0.5rem 0.8rem;

      .pl-detail-text {
        display: none;
      }
    }
  }

  .leftside {
    .menu-section {
      li {
        .submenu {
          left: 0;
          top: 30px;
        }
      }
    }
  }

  .notification-item {
    .d-flex {
      display: block !important;

      h6, small, p {
        padding-left: 15px;
      }
    }

  }

  .sharesecetion {
    display: block !important;
  }
  #alertholder {
    left: 0;
    width: 100%;
  }
  .login-form{
    width: 100%;
  }
}