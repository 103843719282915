@import "../../assets/css/global";

.formContainer {
  min-height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;

  .btn {
    padding: 1rem;
    //border: none;
    width: 15.5rem;
    color: white;
    font-weight: 600;
  }

  .nextbtn {
    background: var(--gradient2);

    &:hover {
      background: var(--primClr);
    }
  }
}

.rpcp111 {
  box-sizing: border-box;
  -moz-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  min-width: 0px;
}

.h47o {
  box-sizing: border-box;
  -moz-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  -moz-box-flex: 5;
  flex: 5 1 auto;
  display: flex;
  min-width: 45%;
}

.a8x12dwz {
  box-sizing: border-box;
  @include md {
    min-width: 85%;
  }
}

.css-jz6d9T {
  box-sizing: border-box;
  width: 40rem;
  min-height: 38rem;
  border-radius: 8px;
  box-shadow: rgba(18, 28, 45, 0.2) 0px 4px 16px 0px;
  padding: 1rem;
  margin-bottom: 4.5rem;
  margin-top: 20px;

  form {
    min-height: 35rem;
  }

  img {
    height: 3.5rem;
    width: fit-content;
    width: auto;
    margin: 0 auto;
    display: block;
  }
}

label {
  margin-bottom: 0.5rem;
  color: var(--inverse-b-w) !important;
}
.small-text{
  font-size: 10px;
  margin: 0 5px;
}