/* `xs` very small devices MEDIA QUERY FOR MOBILE PHONES WITH SMALL SCREENS*/
@media (max-width: 375px) {
  .projectswicther {
    .nav-link {
      padding: 0.2rem 0.5rem;

      img {
        height: 20px;
      }
    }
  }

  .h-captcha {
    iframe {
      width: 245px !important;
    }
  }
}