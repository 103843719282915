/* // `lg` applies to medium devices (tablets, less than 992px) MEDIA QUERY FOR TABLETS*/
@media (max-width: 991.98px) {
  .viewTrans{
    width: 400px !important;
  }
  .menutoggler {
    display: block;
    margin-right: 15px;
  }
  .leftside {
    display: none;
  }
  .closemenu {
    display: unset;
  }
  .projectswicther {
    .nav-link {
      padding: 0.5rem 0.5rem;

      img {
        height: 25px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .mainnetinfo {
    span, b {
      font-size: 13px;
    }
  }
}