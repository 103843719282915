@import "responsive.scss";

.messageCard {
  overflow: hidden;
  padding: 10px;
  position: relative;
  @include mobile {
    padding: 0;
  }

  button {
    border: none;
  }

  .conversationInputPopup {
    position: absolute;
    height: 20%;
    width: 40%;
    background-color: lightgray;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
    margin-left: 20%;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
    -webkit-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
    -moz-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
    z-index: 9;

    &.active {
      display: flex;
    }

    @include tab {
      width: 80%;
      margin-left: 5%;
      margin-top: 30%;
    }

    input {
      width: 70%;
      height: 40px;
      border-radius: 5px;
      border: 1px solid var(--primClr);
      padding: 0px 10px;

      &:focus {
        outline: var(--primClr);
      }
    }

    button {
      border: none;
      background-color: var(--primClr);
      color: white;
      padding: 10px;
      margin-top: 5px;
      width: 150px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .menu {
    cursor: pointer;
    display: none;
    position: absolute;
    @include tab {
      display: flex;
    }
  }

  .wrapper {
    //display: flex;
    //gap: 20px;
    height: 72vh;
    @include tab {
      flex-direction: column-reverse;
    }

    .left {
      flex: 3;
      padding: 20px;
      @include mobile {
        padding: 2px;
      }
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .loadmore {
        @include mobile {
          padding: 45px;
        }
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          background-color: var(--projeswth-color);
          padding: 12px;
          color: white;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      .not {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        h3 {
          text-align: center;
          color: lightgray;
          font-size: 3em;
          @include tab {
            font-size: 1.4em;
          }
        }
      }

      .receiverCard {
        display: flex;
        gap: 10px;
        width: 60%;
        margin-bottom: 20px;
        @include tab {
          width: 90%;
        }

        .img {
          padding: 0;

          img {
            height: 40px;
            width: 40px;
            object-fit: contain;
          }
        }

        .messageCon {
          display: flex;
          flex-direction: column;

          .details {
            display: flex;
            gap: 5px;
            align-items: center;

            p {
              font-weight: bold;
            }

            span {
              color: lightgray;
            }
          }

          .message {
            background-color: var(--card-color);
            padding: 10px;
            border-radius: 4px;
            box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
            -webkit-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
            -moz-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
          }
        }
      }

      .senderCard {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        width: 60%;
        float: right;
        margin-bottom: 20px;
        @include tab {
          width: 90%;
        }

        .img {
          padding: 0;

          img {
            height: 40px;
            width: 40px;
            object-fit: contain;
          }
        }

        .messageCon {
          display: flex;
          flex-direction: column;

          .details {
            display: flex;
            flex-direction: row-reverse;
            gap: 5px;
            align-items: center;

            p {
              font-weight: bold;
            }

            span {
              color: lightgray;
            }
          }

          .message {
            background-color: $messageCard;
            color: white;
            padding: 10px;
            border-radius: 4px;
            box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
            -webkit-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
            -moz-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
          }
        }
      }
    }

    .right {
      //flex: 1;
      display: flex;
      height: 60px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      top: 2px;
      position: absolute;
      margin: 0 auto;
      border-radius: 8px;
      box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
      -webkit-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
      -moz-box-shadow: 2px 3px 7px -3px rgba(0, 0, 0, 0.58);
      //@include tab {
      //  display: none;
      //}
      //
      //&.active {
      //  @include tab {
      //    position: absolute;
      //    display: block;
      //    width: 250px;
      //    right: 0;
      //    top: 0;
      //  }
      //}

      .img {
        width: 100%;
        height: 160px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      .nameDet {
        padding: 20px;
        margin-bottom: 0px;
        text-align: center;

        span {
          color: lightgray;
        }

        .place {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      }

      .userInfo {
        padding: 5px 15px;

        span {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            font-size: 16px;
          }

          .material-icons {
            color: lightgray;
            cursor: pointer;
          }
        }

        .det {
          margin-top: 10px;

          p {
            font-size: 14px;
            color: lightgray;
          }
        }
      }

      .chatLists {
        button {
          padding: 5px;
          width: 100%;
          background-color: $messageCard;
          border: none;
          color: white;
          cursor: pointer;
        }

        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          height: 240px;

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            cursor: pointer;
            padding-left: 15px;
            padding: 10px;

            &:hover {
              background-color: lightgray;
            }
          }
        }
      }
    }
  }

  .input {
    width: 100%;

    .row {

      .col {
        //border: 1px solid lightgray;
        //border-radius: 8px;
        //padding: 5px;
        //background-color: white;
        display: flex;
        align-items: center;
        gap: 20px;
        height: 60px;
        width: 100%;

        .input-group {
          position: relative;
          justify-content: space-between;
        }

        .form-floating {

          textarea {
            &::-webkit-scrollbar {
              display: none;
            }

            height: 46px !important;
            width: 100%;
            border: 1px solid var(--primClr);

            &:focus {
              outline: none;
            }
          }
        }

        .input-group-text {
          background-color: var(--primClr);
          border: 1px solid var(--primClr);

          button {
            cursor: pointer;
            border: none;
            background-color: var(--primClr);
            height: 60%;
            width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .chatZone {
    height: 59vh;

    @include mobile {
      height: 62.5vh;
    }
    overflow: auto;
    width: 100%;
    position: relative;
    margin-top: 66px;

    .scrollButton {
      position: fixed;
      bottom: 30px;
      right: 9%;
      @include mobile {
        right: 1.3%;
      }
      @include tab {
        right: 6%;
      }
      background-color: rgba(128, 128, 128, 0.5);
      border: none;
      color: white;
      padding: 10px;
      cursor: pointer;
      z-index: 200000;
      border-radius: 9999px;
      transition: background-color 0.3s;
    }

    .scrollButton:hover {
      background-color: rgba(128, 128, 128, 1);
    }

    .scrollButtonTop {

      top: 21%;
      @include l {
        top: 27%;
      }
      @include tab {
        top: 36%;
      }
      bottom: unset;
    }

    .scrollButtonEnd {
      bottom: 23%;
      @include tab {
        bottom: 21%;
      }
    }
  }


}

.actTxt {
  font-size: .5rem;
}