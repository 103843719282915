@import "colors/light";
@import "colors/dark";

$lg-desktopWidth: 1399.98px;
$desktopWidth: 1280px;
$lg-tabWidth: 1024.98px;
$tabWidth: 768px;
$mobileWidth: 575.98px;
$s-mobileWidth: 375px;



@mixin xxl {
  @media (max-width: #{$lg-desktopWidth}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$desktopWidth}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg-tabWidth}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$tabWidth}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$mobileWidth}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$s-mobileWidth}) {
    @content;
  }
}

