

:root {
  --main-container:linear-gradient(315deg, #b8c6db 0%, #f5f7fa 100%);
}
body.dark-theme{
  --main-container:linear-gradient(315deg, #223044 0%, #1a2433 100%);
}
.quiz-main-body {
  background-color: #b8c6db;
  background-image: var(--main-container);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;

.quiz-container {
  background-color: var(--body-color);
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgba(100, 100, 100, 0.1);
  overflow: hidden;
  width: 600px;
  max-width: 100%;
}

.quiz-header {
  padding: 1rem;
}

h2 {
  padding: 1rem;
  text-align: center;
  margin: 0;
  color: var(--inverse-b-w) !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.2rem;
  margin: 1rem 0;
  color: var(--inverse-b-w) !important;
}

ul li label {
  cursor: pointer;
  margin-left: 5px
}

button,a.submit {
  background-color: var(--primClr);
  border: none;
  color: white;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 1.1rem;
  width: 100%;
  padding: 1.3rem;
  text-align: center;
  text-decoration: none;
}

button:hover,a.submit:hover {
  background-color: var(--primClr);
}
.timer{
  background-color: rgba(252, 37, 37, 0.54);
  width: 7.5em;
  border-radius: 1.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7em 1.8em;
  color: var(--inverse-b-w);
  i{
    color: #d52121;

  }
}
button:focus,a.submit:focus {
  background-color: var(--primClr);
  outline: none;
}
}