$messageCard: rgb(8, 187, 119);

$mobWidth: 480px;
$tabWidth: 768px;
$lWidth: 1024px;
$xlWidth: 1200px;

@mixin xl {
  @media (max-width: #{$xlWidth}) {
    @content;
  }
}
@mixin l {
  @media (max-width: #{$lWidth}) {
    @content;
  }
}
@mixin tab {
  @media (max-width: #{$tabWidth}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobWidth}) {
    @content;
  }
}
