:root {
  --color1: #239c50;
  --color2: #1c7d40;
  --color3: #0e3e20;
  --color4: #eafaf0;
}
.loginForm {
  box-sizing: border-box;
  width: 25rem;
  height: 38rem;
  border-radius: 8px;
  box-shadow: rgba(18, 28, 45, 0.2) 0px 4px 16px 0px;
  padding: 1rem;
  margin-bottom: 4.5rem;

}

.loginContainer {
  min-height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.rpcp111 {
  box-sizing: border-box;
  -moz-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  min-width: 0px;
}

.h47o {
  box-sizing: border-box;
  -moz-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  -moz-box-flex: 5;
  flex: 5 1 auto;
  display: flex;
  min-width: 45%;
}

.a8x12z {
  box-sizing: border-box;
  width: 70%;
}

.css-18wvllj {
  box-sizing: border-box;
  display: flex;
  padding: 1.25rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgb(250, 247, 253);
  color: var(--color3);
  border-color: var(--color3);
}

.css-r721a9 {
  box-sizing: border-box;
  margin-right: 1.25rem;
  padding-top: 0.125rem;
}

.css-lwy70 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex: 1 1 0%;
}

.css-30z9dv {
  margin: 0px;
  padding: 0px;
  color: var(--color1);
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}



.css-r721a9 {
  box-sizing: border-box;
  margin-right: 1.25rem;
  padding-top: 0.125rem;
}

.css-9ku9xq {
  box-sizing: border-box;
  line-height: 0;
  display: block;
  color: var(--color1);
  width: 1.25rem;
  height: 1.25rem;
}
.cs84jfbtn{
  background-color:  var(--color1) !important;
  border-color:  var(--color1);
  &:hover{
    background-color:#1c7d40 !important;
    border-color: #1c7d40;
  }
}
.css-lwy70 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex: 1 1 0%;
}

.css-30z9dv {
  margin: 0px;
  padding: 0px;
  color: currentcolor;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.css-jz6rhf {
  box-sizing: border-box;
  width: 25rem;
  height: 38rem;
  border-radius: 8px;
  box-shadow: rgba(18, 28, 45, 0.2) 0px 4px 16px 0px;
  padding: 1rem;
  margin-bottom: 4.5rem;
  margin-top: 20px;
}

.css-1crlkb6 {
  box-sizing: border-box;
  -moz-box-pack: start;
  justify-content: flex-start;
  -moz-box-align: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 2.25rem;
  min-width: 0px;
  img{
    max-height: 120px;
  }
}

.css-134k5z9 {
  box-sizing: border-box;
  width: 120px;
  //height: 47px;
  margin-bottom: 1.75rem;
}

.css-871z51 {
  margin: 0px;
  padding: 0px;
  color: rgb(18, 28, 45);
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  display: block;
}

.css-1w53erm {
  box-sizing: border-box;
  margin-top: 1.75rem;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 3.75rem;
}

.css-1w53erm {
  box-sizing: border-box;
  margin-top: 1.75rem;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 3.75rem;
}

.css-1brb0pv {
  box-sizing: border-box;
  display: block;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.css-qpdxik {
  box-sizing: border-box;
  margin-bottom: 1.75rem;
}

.css-roynbj {
  box-sizing: border-box;
}
