:root {
  --mainFont: "Nunito", sans-serif !important;
  --primClr: #239c50;
  --color1: #054747;
  --color2: #efefef;
  --color3: #186835;
  --mainnetinfo: #e7e4e4;
  --card-color: #e6e9f6;
  --card-border: #aba9a9;
  --projeswth-color: rgba(255, 3, 3, 0.57);
  --body-color: #ffffff;
  --inverse-w-b: #000;
  --tablecolor: #000;
  --inverse-b-w: #000;
  --textcolor: #fff;
  --color4: #3f52ce;
  --inputcolor: #f8f8f8;
  --inputcolorborder: #26a655;
  --gradient2: linear-gradient(45deg, #43a047, #1de9b6) !important;
  --backgrdcolor3: #054747;
  --notificationHover: rgba(123, 126, 126, 0.29);

  //--ck-color-base-background:var(--card-color);
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: hsl(0, 0%, 98%);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);
  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);
}

.ck {
  .ck-label {
    color: #1a1d20 !important;
  }
 .ck-editor__main{
      color: #000!important;
  }
  .ck-button__label {
    span {
      color: #1a1d20 !important;
    }
  }
}
