
body.dark-theme {

  //--ck-color-base-background:var(--card-color);
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: hsl(0, 0%, 100%);
  --ck-color-shadow-drop: hsla(0, 0%, 100%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);
  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  --primClr: #186835;
  --color1: #054747;
  --color2: #383838;
  --color3: #0b5025;
  --card-border: #575656;
  --inputcolor: #383838;
  --mainnetinfo: #4777f5;
  --card-color: #222;
  --tablecolor: #c2bfbf;
  --inverse-w-b: #fff;
  --inverse-b-w: #fff;
  --textcolor: #fff;
  --body-color: #222;
  --projeswth-color: #e33f3f;
  --notificationHover: #054747;

  .css-jz6rhf,.css-jz6d9T {
    background-color: #444444;
    box-shadow: rgb(154, 152, 152) 0px 4px 16px 0px;
  }

  .form-floating {
    label {
      color: white;
    }

    input {
      border-color: var(--primClr);
    }

    input[type="search"]::placeholder {
      color: #fff !important;
    }
  }
}